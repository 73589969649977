/* eslint-disable @typescript-eslint/no-explicit-any */

import useAxiosPrivate from "@/hooks/useAxiosPrivate";

import { Card, CardContent, CardHeader } from "@/components/ui/card";

import { formatDate } from "@/utils";
import { Bold, Flex, Text } from "@tremor/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Comments() {
	const [comments, setComments] = useState<any[]>([]);

	const navigate = useNavigate();
	const location = useLocation();
	const axiosPrivate = useAxiosPrivate();

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		const getComments = async () => {
			try {
				const result = await axiosPrivate.get("/auth/users/comments", {
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
					signal: controller.signal,
				});
				console.log("comments", result?.data?.comments);
				isMounted && setComments(result?.data?.comments || []);
			} catch (err) {
				console.error(err);
				navigate("/login", { state: { from: location }, replace: true });
			}
		};

		getComments();

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, []);

	return (
		<div className="flex-1 space-y-4 p-8 pt-6">
			<div className="flex items-center justify-start space-y-2">
				<h2 className="text-2xl font-bold tracking-tight">Σχόλια</h2>
			</div>
			<div className="flex flex-col space-y-4">
				{comments.map((comment, index) => (
					<Card key={index}>
						<CardHeader></CardHeader>
						<CardContent>
							<Bold>{comment?.message}</Bold>
							<Flex className="justify-between items-center">
								<Bold>{comment?.email}</Bold>
								<Text className="text-sm text-muted-foreground">
									{formatDate(comment?.created_at)}
								</Text>
							</Flex>
						</CardContent>
					</Card>
				))}
			</div>
		</div>
	);
}
