// src/store/AppStore.tsx
import { create } from "zustand";

interface AppStore {
	isDarkMode: boolean;
	toggleDarkMode: () => void;
	user: { email: string | null; role: string | null };
	setUser: (user: { email: string | null; role: string | null }) => void;
	clearUser: () => void;
}

const useAppStore = create<AppStore>((set) => ({
	isDarkMode: false,
	toggleDarkMode: () => set((state) => ({ isDarkMode: !state.isDarkMode })),
	user: { email: null, role: null },
	setUser: (user) => set({ user }),
	clearUser: () => set({ user: { email: null, role: null } }),
}));

export default useAppStore;
