/* eslint-disable @typescript-eslint/no-explicit-any */

// import { axiosPrivate } from "@/api/axios";
import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import { formatDate } from "@/utils";
import { useEffect, useState } from "react";
import ReusableTable from "../components/ReusableTable";

interface LogData {
	data: {
		email: string;
		action: string;
		kind: string;
		is_manual: boolean;
		subscription_id: string;
		created_at: string;
	}[];
}

export default function OrganizationLogs({ org_id }) {
	const [data, setData] = useState<LogData | null>(null);
	const axiosPrivate = useAxiosPrivate();
	const addParams = ["?"];
	if (org_id) {
		addParams.push(`org_id=${org_id}`);
	}

	useEffect(() => {
		const getUserLogs = async () => {
			const response = await axiosPrivate.get(
				`/auth/users/logs${addParams.join("")}`,
			);
			console.log(response.data);
			setData(await response.data);
		};
		getUserLogs();
	}, []);

	const columns = [
		{
			key: "email",
			header: "Email",
			render: (value: string) => <div>{value}</div>,
		},
		{
			key: "action",
			header: "Credit",
			render: (value: string) => (
				<div>{value === "add" ? "Καταναλώθηκε" : "Αποζημιώθηκε"}</div>
			),
		},
		{
			key: "kind",
			header: "Ενέργεια",
			render: (value: string) => (
				<div>{value.includes("check") ? "Επανέλεγχος" : "Εισαγωγή"}</div>
			),
		},
		{
			key: "kind",
			header: "Τύπος",
			render: (value: string) => (
				<div>{value.includes("afm") ? "ΑΦΜ" : "KAEK"}</div>
			),
		},
		{
			key: "is_manual",
			header: "Χειροκίνητα",
			render: (value: boolean) => <div>{value ? "Ναι" : "Οχι"}</div>,
		},
		{
			key: "subscription_id",
			header: "id Συνδρομής",
			render: (value: boolean) => <div>{value}</div>,
		},
		{
			key: "created_at",
			header: "Ημ/νία Εγγραφής",
			render: (value: string) =>
				formatDate(value, { simple: true, withTime: true }),
		},
	];
	return (
		<div className="flex-1 space-y-4 p-8 pt-6">
			<div className="flex items-center justify-start space-y-2">
				<h2 className="text-2xl font-bold tracking-tight">Organization Logs</h2>
			</div>
			<div className="flex flex-col space-y-4">
				{/* {JSON.stringify(data)} */}

				<ReusableTable
					data={data?.data || []}
					columns={columns}
					// onRowClick={handleRowClick}
					searchableFields={["email"]}
				/>
			</div>
		</div>
	);
}
