import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import { PlusIcon } from "@heroicons/react/outline";
import { Button, TextInput } from "@tremor/react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RequiredLabel = ({ children }) => (
	<span>
		{children} <span className="text-red-500">*</span>
	</span>
);

const AddProduct = ({ getProducts }: { getProducts: any }) => {
	const [name, setName] = useState("");

	const [description, setDescription] = useState("");
	const [popOpen, setPopOpen] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();
	const axiosPrivate = useAxiosPrivate();

	async function handleSubmit(
		isMounted = true,
		controller = new AbortController(),
	) {
		try {
			await axiosPrivate.post(
				"/auth/products",
				{
					name: name.trim(),

					description: description.trim(),
				},
				{
					signal: controller.signal,
				},
			);

			await getProducts(isMounted, controller);
		} catch (error) {
			console.error(error);
			navigate("/login", { state: { from: location }, replace: true });
		} finally {
			setPopOpen(false);
			setName("");

			setDescription("");
		}
	}

	return (
		<Button className="ml-2">
			<Popover open={popOpen}>
				<PopoverTrigger onClick={() => setPopOpen(!popOpen)}>
					Προσθήκη
				</PopoverTrigger>
				<PopoverContent
					className="w-fit mt-4"
					onPointerDownOutside={() => setPopOpen(false)}
				>
					<div className="grid gap-4">
						<div className="space-y-2">
							<h4 className="font-medium leading-none">Προσθήκη Προϊόντος</h4>
							<form
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								<div className="space-y-2">
									<div className="flex flex-col items-start space-y-1">
										<label
											htmlFor="name"
											className="text-sm font-medium text-muted-foreground"
										>
											<RequiredLabel>Όνομα Προϊόντος</RequiredLabel>
										</label>
										<TextInput
											id="name"
											placeholder="Όνομα Προϊόντος"
											value={name}
											onValueChange={setName}
											required
										/>
									</div>

									<div className="flex flex-col items-start space-y-1">
										<label
											htmlFor="description"
											className="text-sm font-medium text-muted-foreground"
										>
											Περιγραφή
										</label>
										<TextInput
											id="description"
											placeholder="Περιγραφή Προϊόντος"
											value={description}
											onValueChange={setDescription}
										/>
									</div>
									<div className="flex flex-row space-x-1 w-full">
										<div className="flex justify-center w-2/3">
											<Button
												color="emerald"
												icon={PlusIcon}
												disabled={!name}
												type="submit"
												className="w-full"
											>
												Προσθήκη
											</Button>
										</div>
										<div className="flex justify-center w-1/3">
											<Button
												color="red"
												className="w-full"
												onClick={() => setPopOpen(false)}
											>
												Ακύρωση
											</Button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</PopoverContent>
			</Popover>
		</Button>
	);
};

export default AddProduct;
