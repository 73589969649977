import { Card, CardContent } from "@/components/ui/card";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import { DateRangePicker, TextInput } from "@tremor/react";
import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from "lucide-react";
import type React from "react";
import { useMemo, useState } from "react";

interface Column {
	key: string;
	header: string;
	render?: (value: any, row: any) => React.ReactNode;
	sortable?: boolean;
}

interface ReusableTableProps {
	data: any[];
	columns: Column[];
	onRowClick?: (row: any) => void;
	searchableFields?: string[];
}

const ReusableTable: React.FC<ReusableTableProps> = ({
	data,
	columns,
	onRowClick,
	searchableFields = [],
}) => {
	const [sortConfig, setSortConfig] = useState<{
		key: string;
		direction: "asc" | "desc";
	} | null>(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [filterDates, setFilterDates] = useState<{ from?: Date; to?: Date }>(
		{},
	);

	const requestFilterDates = (from, to) => {
		const fromDate = from ? new Date(from) : from;
		const toDate = to ? new Date(to) : to;
		setFilterDates({ from: fromDate, to: toDate });
	};
	const filteredDates = useMemo(() => {
		const filtered = data?.filter((e) => {
			const fromDate = filterDates.from;
			const toDate = filterDates.to;
			const current = new Date(e.created_at);
			if (fromDate === undefined && toDate === undefined) {
				return e;
			}
			if ((fromDate ?? new Date(0)) <= current && toDate === undefined) {
				console.log("from");
				return e;
			}
			if ((toDate ?? new Date()) >= current && fromDate === undefined) {
				console.log("to");
				return e;
			}
			if (
				(fromDate ?? new Date(0)) <= current &&
				current <= (toDate ?? new Date())
			) {
				console.log("both");
				return e;
			}
		});
		return filtered;
	}, [data, filterDates]);

	const sortedData = useMemo(() => {
		// check if data is an array
		if (!Array.isArray(filteredDates)) return [];

		const sortableItems = [...filteredDates];
		if (sortConfig !== null) {
			sortableItems.sort((a, b) => {
				if (a[sortConfig.key] < b[sortConfig.key]) {
					return sortConfig.direction === "asc" ? -1 : 1;
				}
				if (a[sortConfig.key] > b[sortConfig.key]) {
					return sortConfig.direction === "asc" ? 1 : -1;
				}
				return 0;
			});
		}
		return sortableItems;
	}, [filteredDates, sortConfig]);

	const filteredData = useMemo(() => {
		if (searchTerm === "") return sortedData;
		return sortedData.filter((row) => {
			const fieldsToSearch =
				searchableFields.length > 0 ? searchableFields : Object.keys(row);
			return fieldsToSearch.some((field) =>
				String(row[field]).toLowerCase()?.includes(searchTerm.toLowerCase()),
			);
		});
	}, [sortedData, searchTerm, searchableFields]);

	const requestSort = (key: string) => {
		let direction: "asc" | "desc" = "asc";
		if (
			sortConfig &&
			sortConfig.key === key &&
			sortConfig.direction === "asc"
		) {
			direction = "desc";
		}
		setSortConfig({ key, direction });
	};

	return (
		<Card className="col-span-4">
			<CardContent className="pt-2 pl-2">
				<div className="flex flex-row items-center justify-between w-full mb-5 mt-5 ">
					<div className="flex items-center">
						<TextInput
							placeholder="Search..."
							value={searchTerm}
							onValueChange={(e) => setSearchTerm(e)}
							className="max-w-sm"
							icon={SearchIcon}
						/>
					</div>
					<div className="">
						<DateRangePicker
							onValueChange={(e) => {
								requestFilterDates(e.from, e.to);
							}}
						/>
					</div>
				</div>
				<Table>
					<TableHeader>
						<TableRow>
							{columns?.map((column, index) => (
								<TableHead
									key={`header-${index}`}
									className="max-w-[250px] cursor-pointer"
									onClick={() =>
										column.sortable !== false && requestSort(column.key)
									}
								>
									<div className="flex items-center">
										{column.header}
										{column.sortable !== false && (
											<span className="ml-2">
												{sortConfig?.key === column.key ? (
													sortConfig.direction === "asc" ? (
														<ChevronUpIcon className="h-4 w-4" />
													) : (
														<ChevronDownIcon className="h-4 w-4" />
													)
												) : (
													<ChevronUpIcon className="h-4 w-4 opacity-0" />
												)}
											</span>
										)}
									</div>
								</TableHead>
							))}
						</TableRow>
					</TableHeader>
					<TableBody>
						{filteredData?.map((row, rowIndex) => (
							<TableRow
								className="cursor-pointer"
								key={`row-${rowIndex}`}
								onClick={() => onRowClick?.(row)}
							>
								{columns?.map((column, cellIndex) => (
									<TableCell key={`cell-${rowIndex}-${cellIndex}`}>
										{column.render
											? column.render(row[column.key], row)
											: row[column.key]}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

export default ReusableTable;
