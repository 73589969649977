import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { formatDate } from "@/utils";
import { Badge } from "@tremor/react";
import { useNavigate, useParams } from "react-router-dom";

const products = [
	{
		id: 1,
		name: "Property",
		created_at: "2023-05-15T10:30:00Z",
		current_users: 150,
		online_users: 42,
	},
	{
		id: 2,
		name: "Portal",
		created_at: "2023-06-22T14:45:00Z",
		current_users: 280,
		online_users: 95,
	},
	{
		id: 3,
		name: "Poinika",
		created_at: "2023-07-10T09:15:00Z",
		current_users: 75,
		online_users: 18,
	},
];

const ProductCard = ({ product }) => {
	const navigate = useNavigate();
	const { org_id } = useParams();
	return (
		<Card
			onClick={() => {
				navigate(`/org/${org_id}/product/${product.id}`);
			}}
			className="cursor-pointer"
		>
			<CardHeader>
				<CardTitle>{product.name}</CardTitle>
			</CardHeader>
			<CardContent>
				<p className="text-sm text-gray-500">
					Created:{" "}
					{formatDate(product.created_at, { simple: true, withTime: true })}
				</p>
				<p className="mt-2">
					<span className="font-semibold">Current Users:</span>{" "}
					{product.current_users}
				</p>
				<p className="mt-1">
					<span className="font-semibold">Online Users:</span>{" "}
					{product.online_users}
				</p>
				<Badge color="green" className="mt-3">
					{((product.online_users / product.current_users) * 100).toFixed(1)}%
					Online
				</Badge>
			</CardContent>
		</Card>
	);
};
const ProductGrid = () => (
	<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
		{products.map((product, index) => (
			<ProductCard key={index} product={product} />
		))}
	</div>
);

export default ProductGrid;
