import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import { formatDate } from "@/utils";

export default function SingleUser({ user_id }: { user_id: string }) {
	const [user, setUser] = useState({} as any);

	const navigate = useNavigate();
	const location = useLocation();
	const axiosPrivate = useAxiosPrivate();

	async function getSingleUser(
		isMounted?: boolean,
		controller?: AbortController,
	) {
		if (isMounted === undefined) {
			isMounted = true;
		}
		if (controller === undefined) {
			controller = new AbortController();
		}

		try {
			const response = await axiosPrivate.get(`/auth/orgs/users/${user_id}`, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
				signal: controller?.signal,
			});

			console.log("response", response.data.user);
			isMounted && setUser(response.data.user);
		} catch (error) {
			console.error(error);
			navigate("/login", { state: { from: location }, replace: true });
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		let isMounted = true;
		getSingleUser(isMounted, controller);

		return () => {
			controller.abort();
			isMounted = false;
		};
	}, [user_id]);

	return (
		<div className="flex-1 space-y-4 p-8 pt-6">
			<div className="flex items-center justify-start space-y-2">
				<h2 className="text-3xl font-bold tracking-tight">{user?.email}</h2>
			</div>
			<div className="flex items-center space-x-2">
				<h3 className="text-xl tracking-tight">
					<span className="font-semibold">Ημ/νία Εγγραφής:</span>{" "}
					{formatDate(user?.created_at)}
				</h3>
			</div>
			<Card className="col-span-4 ">
				<CardHeader>
					<CardTitle>Τρέχουσα Συνδρομή</CardTitle>
				</CardHeader>
				<CardContent className=""></CardContent>
			</Card>
		</div>
	);
}
