import { MainNav } from "@/Pages/components/main-nav";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Outlet, useLocation } from "react-router-dom";
import { Toaster } from "sonner";

const Layout = () => {
	const location = useLocation();

	return (
		<div className="flex-col flex">
			<div className="border-b">
				<div className="flex h-16 items-center px-4">
					<Avatar>
						<AvatarImage src="/nb/android-chrome-192x192.png" />
						<AvatarFallback>CN</AvatarFallback>
					</Avatar>
					{location.pathname !== "/login" && <MainNav className="mx-6" />}
					<div className="ml-auto flex items-center space-x-4">
						{/* <Search /> */}
						{/* <UserNav /> */}
					</div>
				</div>
			</div>
			<Outlet />
			<Toaster position="top-center" richColors />
		</div>
	);
};

export default Layout;
