import { CheckCircleIcon, SaveIcon } from "@heroicons/react/outline";
import { Button, MultiSelect, MultiSelectItem, TextInput } from "@tremor/react";
import MDEditor, { getExtraCommands } from "@uiw/react-md-editor";
import type React from "react";
import { useEffect, useState } from "react";

import { formatDate } from "@/utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
// import { formatDate } from "@/utils";

const KtimaMessaging = () => {
	const [messages, setMessages] = useState([]);
	const [message, setMessage] = useState({ users: [] } as any);
	const [uuid, setUuid] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [notFound, setNotFound] = useState(false);
	const { uuid: uuidParam } = useParams();
	const [users, setUsers] = useState([] as any);

	// const [announcements, setAnnouncements] = useState([]);

	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		getAllMessages(isMounted, controller);
		getAllUsers(isMounted, controller);

		return () => {
			isMounted = false;
			controller.abort();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (uuidParam) {
			// find the message with the uuid
			const message = messages.find(
				(message: any) => message.uuid === uuidParam,
			) as any;
			if (!message) {
				setNotFound(true);
				return;
			}
			console.log("message", message);
			setNotFound(false);
			setMessage(message);
			setUuid(message.uuid);
		}
	}, [uuidParam, messages]);

	// useEffect(() => {
	//   let isMounted = true;
	//   const controller = new AbortController();

	//   const fetchAnnouncements = async () => {
	//     try {
	//       const response = await axiosPrivate.get("/ktimaannouncement/fetch", {
	//         signal: controller.signal,
	//       });
	//       isMounted && setAnnouncements(response.data);
	//     } catch (err) {
	//       console.error(err);
	//       if (!axios.isCancel(err)) {
	//         // do something
	//         navigate("/login", { state: { from: location }, replace: true });
	//       }
	//     }
	//   };

	//   fetchAnnouncements();

	//   return () => {
	//     isMounted = false;
	//     controller.abort();
	//   };
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	// const handleSubmit = async (e: { preventDefault: () => void; }) => {
	//   e.preventDefault();

	//   try {
	//     await axiosPrivate.post(
	//       "/ktimaannouncement/new",
	//       JSON.stringify({ body: value, title: "Νέα ανακοίνωση" }),
	//       {
	//         headers: { "Content-Type": "application/json" },
	//         withCredentials: true,
	//       }
	//     );
	//   } catch (err) {
	//     console.error(err);
	//     navigate("/login", { state: { from: location }, replace: true });
	//   }
	// };
	// event is of type React.FormEvent<HTMLFormElement>
	async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		const subject = message.subject;
		const summary = message.summary;
		const body = message.body;

		setIsLoading(true);

		try {
			const response = await axiosPrivate.post(
				"/auth/messaging/new",
				JSON.stringify({ subject, summary, body, uuid }),
				{
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
				},
			);

			console.log("response", response);
			setUuid(response.data.uuid);
			getAllMessages();
		} catch (err) {
			console.error(err);
			navigate("/login", { state: { from: location }, replace: true });
		} finally {
			setIsLoading(false);
		}
	}
	function addParagraphBeforeImage() {
		// check if paragraph is followed by an image
		// if yes, don't add a new paragraph
		// if (value.split("\n\n").slice(-1)[0].includes("![Image](")) {
		//   return;
		// }
		// setValue(value);
	}
	const uploadImage = async (file: string | Blob) => {
		// Implement image upload logic here
		// For example, using FormData and axiosPrivate to upload to a server
		const formData = new FormData();
		formData.append("profilePicture", file);

		try {
			const response = await axiosPrivate.post("/upload", formData, {
				headers: { "Content-Type": "multipart/form-data" },
				withCredentials: true,
			});

			setMessage({
				...message,
				body: `${message.body} ![Image](https://ktima-img.s3.nl-ams.scw.cloud/${response.data.uuid}.jpg)`,
			});

			return response.data.imageUrl; // Replace with the path to the image in your response
		} catch (error) {
			console.error("Error uploading image: ", error);
			return null;
		}
	};

	async function getAllMessages(
		isMounted?: boolean,
		controller?: AbortController,
	) {
		if (isMounted === undefined) {
			isMounted = true;
		}
		if (controller === undefined) {
			controller = new AbortController();
		}

		try {
			const response = await axiosPrivate.get("/auth/messaging/all", {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
				signal: controller?.signal,
			});

			isMounted &&
				setMessages(
					response.data.map((message: any) => {
						return {
							...message,
							users: [],
						};
					}),
				);
		} catch (error) {
			console.error(error);
			navigate("/login", { state: { from: location }, replace: true });
		}
	}
	async function getAllUsers(
		isMounted?: boolean,
		controller?: AbortController,
	) {
		if (isMounted === undefined) {
			isMounted = true;
		}
		if (controller === undefined) {
			controller = new AbortController();
		}

		try {
			const response = await axiosPrivate.get("/auth/users", {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
				signal: controller?.signal,
			});
			console.log("users", response.data);

			isMounted && setUsers(response.data);
			setMessage({
				...message,
				users: response.data.users.map((user: any) => user.email),
			});
		} catch (error) {
			console.error(error);
			navigate("/login", { state: { from: location }, replace: true });
		}
	}
	async function sentMessage(
		isMounted?: boolean,
		controller?: AbortController,
	) {
		if (isMounted === undefined) {
			isMounted = true;
		}
		if (controller === undefined) {
			controller = new AbortController();
		}

		console.log("message", message);

		try {
			await axiosPrivate.post(
				"/auth/messaging/outbox",
				JSON.stringify({ message_uuid: uuid, emails: message.users }),
				{
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
					signal: controller?.signal,
				},
			);
		} catch (error) {
			console.error(error);
			navigate("/login", { state: { from: location }, replace: true });
		}
	}

	useEffect(() => {
		addParagraphBeforeImage();
	}, [message.body]);

	const imageUploadCommand = {
		name: "imageUpload",
		keyCommand: "imageUpload",
		buttonProps: { "aria-label": "Upload image" },
		icon: <span>🖼️</span>, // Replace with your icon
		execute: async (
			_state: any,
			api: { replaceSelection: (arg0: string) => void },
		) => {
			const input = document.createElement("input");
			input.type = "file";
			input.accept = "image/*";
			input.onchange = async () => {
				const file = input.files?.[0];
				if (file) {
					const imageUrl = await uploadImage(file);
					if (imageUrl) {
						const markdownImage = `![Image](${imageUrl})`;
						api.replaceSelection(markdownImage);
					}
				}
			};
			input.click();
			// Return true to tell the editor that the command has been handled
			return true;
		},
	};

	return (
		<div className="flex-1 space-y-4 p-8 pt-6">
			<div className="flex items-center justify-start space-y-2">
				<h2 className="text-3xl font-bold tracking-tight">Dashboard</h2>

				<div className="flex items-center space-x-2">
					{/* <CalendarDateRangePicker />
          <Button>Download</Button> */}
				</div>
			</div>
			<div className="grid gap-8 md:grid-cols-4 lg:grid-cols-8">
				<div className="md:col-span-1 lg:col-span-1">
					<h2>Προηγούμενα Μηνύματα</h2>
					<ul className="message-list">
						{messages.map((item: any) => (
							<li
								key={item.id}
								className="message-item"
								onClick={() => {
									navigate(`/messaging/${item.uuid}`);
								}}
							>
								<h3>{item.subject}</h3>

								<p>
									{formatDate(item.created_at, {
										simple: true,
										withTime: true,
									})}
								</p>
							</li>
						))}
					</ul>
				</div>
				{!notFound && (
					<>
						<form
							onSubmit={handleSubmit}
							className="flex flex-col space-y-2 md:col-span-2 lg:col-span-5"
						>
							<h2>Νέο Μήνυμα</h2>
							<TextInput
								placeholder="Θέμα"
								className="w-full"
								id="subject"
								required
								value={message.subject}
								onValueChange={(value) => {
									setMessage({
										...message,
										subject: value,
									});
								}}
							/>
							<TextInput
								placeholder="Υποκεφαλίδα"
								className="w-full"
								id="summary"
								required
								value={message.summary}
								onValueChange={(value) => {
									setMessage({
										...message,
										summary: value,
									});
								}}
							/>
							<div className="md-container " data-color-mode="light">
								<div className="wmde-markdown-var"> </div>
								<MDEditor
									value={message.body}
									// @ts-ignore
									onChange={(value) => {
										setMessage({
											...message,
											body: value,
										});
									}}
									extraCommands={[...getExtraCommands(), imageUploadCommand]}
									preview="edit"
								/>
								<MDEditor.Markdown
									source={message.body}
									style={{ whiteSpace: "pre-wrap" }}
								/>
								{/* A list with the titles and the dates */}
								<Button
									loading={isLoading}
									icon={uuid !== "" ? CheckCircleIcon : SaveIcon}
									type="submit"
								>
									{uuid !== "" ? "Αποθηκεύτηκε" : "Αποθήκευση"}
								</Button>
							</div>

							{/* <ul>
        {announcements.map((item: any) => (
          <li key={item.id}>
            <h3>{item.title}</h3>
            <p>{item.body}</p>
            <p>{formatDate(item.created_at)}</p>
          </li>
        ))}
      </ul> */}
						</form>
						<div className="md:col-span-1 lg:col-span-2">
							<h2>Παραλήπτες</h2>
							<MultiSelect
								className="w-full"
								id="users"
								placeholder="Παραλήπτες"
								value={message.users}
								onValueChange={(value) => {
									console.log("value", value);
									console.log("uuid", uuid);
									setMessage({
										...message,
										users: value,
									});
								}}
							>
								{users?.users?.map((user: any) => (
									<MultiSelectItem key={user.id} value={user.email}>
										{user.email}
									</MultiSelectItem>
								))}
							</MultiSelect>
							<Button
								onClick={() => {
									sentMessage();
								}}
							>
								Αποστολή
							</Button>
						</div>
					</>
				)}

				{notFound && (
					<div className="col-span-3">
						<h2>Δεν βρέθηκε το μήνυμα</h2>
					</div>
				)}
			</div>
		</div>
	);
};

export default KtimaMessaging;
