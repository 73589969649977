import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import {
	Button,
	MultiSelect,
	MultiSelectItem,
	NumberInput,
	Select,
	SelectItem,
	Switch,
	TextInput,
} from "@tremor/react";
import { PackageIcon } from "lucide-react";
import type React from "react";
import { useState } from "react";

type LengthType = "month" | "year" | "demo" | "open";

interface Package {
	id: string;
	name: string;
	details: {
		allowed_credits?: number;
		allowed_users?: number;
		length_type?: LengthType[];
		frequency?: "weekly" | "24" | "48" | "adhoc";
		freq_editable?: boolean;
	};
}
interface AddPackageProps {
	onAddPackage: (newPackage: Omit<Package, "id">) => Promise<void>;
}

const AddPackage: React.FC<AddPackageProps> = ({ onAddPackage }) => {
	const [newPackage, setNewPackage] = useState<Omit<Package, "id">>({
		name: "",
		details: {
			allowed_credits: 0,
			allowed_users: 1000,
			length_type: ["year"],
			frequency: "weekly",
			freq_editable: true,
		},
	});
	const [popOpen, setPopOpen] = useState(false);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		await onAddPackage(newPackage);
		setNewPackage({
			name: "",
			details: {
				allowed_credits: 0,
				allowed_users: 1000,
				length_type: ["year"],
				frequency: "weekly",
				freq_editable: true,
			},
		});
		setPopOpen(false);
	};

	return (
		<Popover open={popOpen}>
			<Button className="ml-2" icon={PackageIcon}>
				<PopoverTrigger
					onClick={() => {
						setPopOpen(!popOpen);
					}}
				>
					Προσθήκη Νέου Πακέτου
				</PopoverTrigger>
			</Button>
			<PopoverContent
				onPointerDownOutside={() => {
					setPopOpen(false);
				}}
				className="w-80 mt-4"
			>
				<div className="grid gap-4">
					<div className="space-y-2">
						<h4 className="font-medium leading-none">Προσθήκη Πακέτου</h4>
						<p className="text-sm text-muted-foreground">
							{/* Enter the details for the new package. */}
							Εισάγετε τις λεπτομέρειες για το νέο πακέτο.
						</p>
						<form onSubmit={handleSubmit}>
							<div className="space-y-2">
								<div className="flex flex-col items-start space-y-1">
									<label
										htmlFor="name"
										className="text-sm font-medium text-muted-foreground"
									>
										Όνομα
									</label>
									<TextInput
										id="name"
										placeholder="Όνομα Πακέτου"
										value={newPackage.name}
										required
										onChange={(e) =>
											setNewPackage({ ...newPackage, name: e.target.value })
										}
									/>
								</div>
								<div className="flex flex-col items-start space-y-1">
									<label
										htmlFor="allowed_credits"
										className="text-sm font-medium text-muted-foreground"
									>
										Επιτρεπόμενα Credits
									</label>
									<NumberInput
										id="allowed_credits"
										placeholder="Allowed Credits"
										value={newPackage.details.allowed_credits}
										required
										onValueChange={(value) =>
											setNewPackage({
												...newPackage,
												details: {
													...newPackage.details,
													allowed_credits: value,
												},
											})
										}
									/>
								</div>
								<div className="flex flex-col items-start space-y-1">
									<label
										htmlFor="allowed_users"
										className="text-sm font-medium text-muted-foreground"
									>
										Επιτρεπόμενοι Χρήστες
									</label>
									<NumberInput
										id="allowed_users"
										placeholder="Allowed Users"
										value={newPackage.details.allowed_users}
										required
										onValueChange={(value) =>
											setNewPackage({
												...newPackage,
												details: {
													...newPackage.details,
													allowed_users: value,
												},
											})
										}
									/>
								</div>
								<div className="flex flex-col items-start space-y-1">
									<label
										htmlFor="length_type"
										className="text-sm font-medium text-muted-foreground"
									>
										Διάρκεια Συνδρομής
									</label>
									<MultiSelect
										id="length_type"
										value={newPackage.details.length_type}
										onValueChange={(value) =>
											setNewPackage({
												...newPackage,
												details: {
													...newPackage.details,
													length_type: value as LengthType[],
												},
											})
										}
									>
										<MultiSelectItem value="month">Μήνας</MultiSelectItem>
										<MultiSelectItem value="year">Έτος</MultiSelectItem>
										<MultiSelectItem value="demo">Demo</MultiSelectItem>
										<MultiSelectItem value="open">Ανοιχτή</MultiSelectItem>
									</MultiSelect>
								</div>
								<div className="flex flex-col justify-between space-y-1 ">
									<div className="flex flex-col items-start space-y-1">
										<label
											htmlFor="length_type"
											className="text-sm font-medium text-muted-foreground"
										>
											Συχνότητα Ελέγχου
										</label>
										<Select
											name="select"
											id="select"
											className="min-w-6  text-slate-950"
											placeholder="Συχνότητα"
											enableClear
											value={newPackage.details.frequency}
											onValueChange={(value) =>
												setNewPackage({
													...newPackage,
													details: {
														...newPackage.details,
														frequency: value as
															| "weekly"
															| "24"
															| "48"
															| "adhoc",
													},
												})
											}
										>
											<SelectItem value="24">24 Ώρες</SelectItem>
											<SelectItem value="48">48 Ώρες</SelectItem>
											<SelectItem value="weekly">Εβδομαδιαία</SelectItem>
											<SelectItem value="adhoc">Χειροκίνητα</SelectItem>
										</Select>
									</div>
									<div className="flex flex-col items-start space-y-1">
										<label
											htmlFor="length_type"
											className="text-sm font-medium text-muted-foreground"
										>
											Επεξεργάσιμη Συχνότητα
										</label>
										<div className="flex items-center space-x-2">
											<Switch
												checked={newPackage.details.freq_editable}
												onChange={(value) =>
													setNewPackage({
														...newPackage,
														details: {
															...newPackage.details,
															freq_editable: value,
														},
													})
												}
											/>
											<span className="text-sm font-medium text-muted-foreground">
												{newPackage.details.freq_editable ? "Ναι" : "Όχι"}
											</span>
										</div>
									</div>
								</div>
								<Button color="emerald" type="submit" className="w-full">
									Προσθήκη Πακέτου
								</Button>
							</div>
						</form>
					</div>
				</div>
			</PopoverContent>
		</Popover>
	);
};

export default AddPackage;
