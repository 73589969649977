/* eslint-disable @typescript-eslint/no-explicit-any */

import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import { formatDate } from "@/utils";
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionList,
	Badge,
} from "@tremor/react";
import { useEffect, useState } from "react";
import ReusableTable from "../components/ReusableTable";
export default function Transactions() {
	const [data, setData] = useState<{ data: any[] } | null>(null);
	const axiosPrivate = useAxiosPrivate();
	const addParams = ["?"];

	useEffect(() => {
		const getUserLogs = async () => {
			const response = await axiosPrivate.get(
				`/auth/nbUsers/logs${addParams.join("")}`,
			);
			setData(await response.data);
		};
		getUserLogs();
	}, []);

	const columns = [
		{
			key: "email",
			header: "Email",
			render: (value: string) => <div>{value}</div>,
		},
		{
			key: "action",
			header: "Ενέργεια",
			render: (value: string) => (
				<Badge
					className=""
					color={
						value === "created"
							? "green"
							: value === "updated"
								? "yellow"
								: "red"
					}
				>
					{value === "created" && "Δημιούργησε"}
					{value === "updated" && "Άλλαξε"}
					{value === "deleted" && "Διέγραψε"}
				</Badge>
			),
		},
		// {
		// 	key: "resource",
		// 	header: "Οντότητα",
		// 	render: (value: string) => (
		// 		<div>
		// 			{value?.includes(",")
		// 				? value.split(",").map((e) => <div key={e}>{e}</div>)
		// 				: value}
		// 		</div>
		// 	),
		// },
		{
			key: "value",
			header: "Τιμές",
			render: (value: string) => {
				const parsed = JSON.parse(value);
				const jsonElements = Object.entries(parsed);
				return (
					<AccordionList style={{ width: "500px" }} className="max-w-sm">
						{jsonElements.map((e) => {
							const data = JSON.parse(JSON.stringify(e[1]));
							return (
								<Accordion key={e[0]} className="max-w-sm">
									<AccordionHeader
										className=" max-w-sm text-sm font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
										key={e[0]}
									>
										{e[0]}
									</AccordionHeader>
									<AccordionBody className=" max-w-l">
										{Array.isArray(data) ? (
											data.map((e) => {
												return (
													<div key={e.key}>
														<b>{e.key}: </b>
														{e.value && <Badge color="blue">{e?.value}</Badge>}
														{e.oldValue !== undefined && (
															<Badge color="orange">{e?.oldValue}</Badge>
														)}
														{e?.newValue !== undefined && (
															<>
																→ <Badge color="green">{e?.newValue}</Badge>
															</>
														)}
													</div>
												);
											})
										) : typeof data === "object" ? (
											Object.entries(data).map(([key, value]) => {
												if (
													key !== "created_at" &&
													key !== "updated_at" &&
													key !== "is_deleted"
												)
													return (
														<div key={key}>
															<b>{key}:</b>
															<span className="text-cyan-500">
																{value as string}
															</span>
														</div>
													);
											})
										) : (
											<div>
												<b>{e[0]}:</b>
												<span className="text-cyan-500"> {data}</span>
											</div>
										)}
									</AccordionBody>
									{/* <div key={e[0]}>{JSON.stringify(e[1])}</div> */}
								</Accordion>
							);
						})}
					</AccordionList>
				);
			},
		},

		{
			key: "created_at",
			header: "Ημ/νία Εγγραφής",
			render: (value: string) =>
				formatDate(value, { simple: true, withTime: true }),
		},
	];
	return (
		<div className="flex-1 space-y-4 p-8 pt-6">
			<div className="flex items-center justify-start space-y-2">
				<h2 className="text-2xl font-bold tracking-tight">Transactions</h2>
			</div>
			<div className="flex flex-col space-y-4">
				{/* {JSON.stringify(data)} */}

				<ReusableTable
					data={data?.data ?? []}
					columns={columns}
					// onRowClick={handleRowClick}
					searchableFields={["email"]}
				/>
			</div>
		</div>
	);
}
