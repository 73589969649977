/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import {
	Card,
	Flex,
	Icon,
	Select,
	SelectItem,
	SparkAreaChart,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeaderCell,
	TableRow,
	Title,
} from "@tremor/react";

import { EyeIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";

// get random number between min and max
function getRandomArbitrary(min: number, max: number) {
	return Math.random() * (max - min) + min;
}

function createChartData(chartLengh: number) {
	const chartData = [];
	for (let i = 0; i < chartLengh; i++) {
		chartData.push({
			month: `Jan ${i}`,
			Performance: getRandomArbitrary(1000, 6000),
			Benchmark: getRandomArbitrary(1000, 6000),
		});
	}
	return chartData;
}

export const SparkAreaExample = ({
	users,
	calls,
	mpUsers,
	mpCalls,
	changeAccountType,
}: {
	users: { email: string; account: string; id: number }[];
	calls: any;
	mpUsers: any;
	mpCalls: any;
	changeAccountType: any;
}) => {
	const navigate = useNavigate();

	async function onChangeAccountType(account: string, email: string) {
		console.log("account", account);
		console.log("email", email);

		changeAccountType(email, account);
	}

	console.log("users", users);
	console.log("calls", calls);
	console.log("mpUsers", mpUsers);
	console.log("mpCalls", mpCalls);
	return (
		<Card className="col-span-7">
			<Title>Χρήστες</Title>
			<Table className="mt-5">
				<TableHead>
					<TableRow>
						<TableHeaderCell>Name</TableHeaderCell>
						<TableHeaderCell>Category</TableHeaderCell>

						<TableHeaderCell>Status</TableHeaderCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{users.map((item) => (
						<TableRow key={item.email}>
							<TableCell
								onClick={() => {
									navigate(`/user/${item.id}`);
								}}
							>
								<Flex alignItems="center" justifyContent="start">
									<Icon icon={EyeIcon} className="pr-2" />
									{item.email}
								</Flex>
							</TableCell>
							<TableCell>
								<Select
									className="w-24"
									defaultValue={item.account}
									onValueChange={(value) =>
										onChangeAccountType(value, item.email)
									}
								>
									<SelectItem value="free">Free</SelectItem>
									<SelectItem value="pro">Pro</SelectItem>
								</Select>
							</TableCell>

							<TableCell>
								<SparkAreaChart
									data={createChartData(25)}
									categories={["Performance"]}
									index={"month"}
									colors={["emerald"]}
									className="h-10 w-36"
								/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Card>
	);
};
