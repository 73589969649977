import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import { formatDate } from "@/utils";
import { PencilIcon } from "@heroicons/react/solid";
import {
	Badge,
	Button,
	NumberInput,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeaderCell,
	TableRow,
} from "@tremor/react";
import axios from "axios";
import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import AddSubscription from "./AddSubscription";

export function checkIfDateIsBetween(
	startDate: string,
	endDate: string,
	today?: string,
) {
	if (!today) {
		today = new Date().toISOString().split("T")[0];
	}
	return today >= startDate && today <= endDate;
}
interface Subscription {
	id: number;
	org_id: number;
	nb_admin_id: number;
	package_id: number;
	allowed_users: number;
	allowed_credits: number;
	used_credits: number;
	note: string | null;
	from_date: string;
	to_date: string;
	is_active: number;
	created_at: string;
	updated_at: string;
	package_name: string;
	package_description: string | null;
	product_name: string;
}
interface SubscriptionRowProps {
	subscription: Subscription;
	setSubscriptions: Dispatch<SetStateAction<Subscription[]>>;
	subscriptions: Subscription[];
}

interface Product {
	id: number;
	name: string;
	description: string;
	created_at: string;
	updated_at: string;
}

const OrgSingleProduct: React.FC = () => {
	const [product, setProduct] = useState<Product | null>(null);
	const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
	const [tab, setTab] = useState("subscriptions");

	const { org_id, product_id } = useParams<{
		org_id: string;
		product_id: string;
	}>();
	const navigate = useNavigate();
	const location = useLocation();
	const axiosPrivate = useAxiosPrivate();

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		const getOrgSingleProductSubscriptions = async () => {
			try {
				const response = await axiosPrivate.get(
					`/auth/orgs/${org_id}/products/${product_id}`,
					{
						signal: controller.signal,
					},
				);
				console.log("Org Single Product Subscriptions", response.data);

				if (isMounted) {
					setProduct(response.data.product);
					setSubscriptions(response.data.subscriptions);
				}
			} catch (err) {
				console.error(err);
				navigate("/login", { state: { from: location }, replace: true });
			}
		};

		getOrgSingleProductSubscriptions();

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [org_id, product_id, axiosPrivate, navigate, location]);

	const handleTabChange = (value: string) => {
		setTab(value);
	};

	const handleSubscriptionAdded = async () => {
		try {
			const response = await axiosPrivate.get(
				`/auth/orgs/${org_id}/products/${product_id}`,
			);
			setSubscriptions(response.data.subscriptions);
		} catch (error) {
			console.error("Error fetching subscriptions:", error);
			// Handle error (e.g., show error message to user)
		}
	};

	return (
		<div className="flex-1 space-y-4 p-8 pt-6">
			<div className="flex items-center justify-between space-y-2">
				<h2 className="text-3xl font-bold tracking-tight">{product?.name}</h2>
				<Button onClick={() => navigate(`/org/${org_id}`)}>
					Πίσω στον Οργανισμό
				</Button>
			</div>

			<Tabs
				defaultValue="subscriptions"
				value={tab}
				onValueChange={handleTabChange}
				className="space-y-4"
			>
				<TabsList>
					{/* <TabsTrigger value="overview">Επισκόπηση</TabsTrigger> */}
					<TabsTrigger value="subscriptions">Συνδρομές</TabsTrigger>
				</TabsList>

				<TabsContent value="overview" className="space-y-4">
					<Card>
						<CardHeader>
							<CardTitle>Product Details</CardTitle>
						</CardHeader>
						<CardContent>
							<p>
								<strong>Description:</strong>{" "}
								{product?.description || "No description available"}
							</p>
							<p>
								<strong>Created At:</strong>{" "}
								{formatDate(product?.created_at || "", {
									simple: true,
									withTime: true,
								})}
							</p>
							<p>
								<strong>Updated At:</strong>{" "}
								{formatDate(product?.updated_at || "", {
									simple: true,
									withTime: true,
								})}
							</p>
						</CardContent>
					</Card>
				</TabsContent>

				<TabsContent value="subscriptions" className="space-y-4">
					<Card>
						<CardHeader>
							<CardTitle>Συνδρομές</CardTitle>
						</CardHeader>
						<CardContent>
							<AddSubscription
								productId={product_id!}
								orgId={org_id!}
								onSubscriptionAdded={handleSubscriptionAdded}
							/>
							<Table>
								<TableHead>
									<TableRow>
										<TableHeaderCell>Πακέτο</TableHeaderCell>
										<TableHeaderCell>Status</TableHeaderCell>
										<TableHeaderCell>Επιτρ.Χρήστες</TableHeaderCell>
										<TableHeaderCell>Credits Συνδρομής</TableHeaderCell>
										<TableHeaderCell>Υπολειπόμενα Credits</TableHeaderCell>

										<TableHeaderCell>Από</TableHeaderCell>
										<TableHeaderCell>Έως</TableHeaderCell>
										<TableHeaderCell>Ενέργειες</TableHeaderCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{subscriptions.map((subscription) => (
										<SubscriptionRow
											subscription={subscription}
											key={subscription.id}
											setSubscriptions={setSubscriptions}
											subscriptions={subscriptions}
										/>
									))}
								</TableBody>
							</Table>
						</CardContent>
					</Card>
				</TabsContent>
			</Tabs>
		</div>
	);
};

export default OrgSingleProduct;

const SubscriptionRow: React.FC<SubscriptionRowProps> = ({
	subscription,
	setSubscriptions,
	subscriptions,
}) => {
	const [editingSubscription, setEditingSubscription] =
		useState<Subscription | null>(null);
	const [popOpen, setPopOpen] = useState(false);

	const axiosPrivate = useAxiosPrivate();

	const handleEdit = (subscription: Subscription) => {
		setEditingSubscription({ ...subscription });
		setPopOpen(true);
	};

	const handleSave = async () => {
		if (!editingSubscription) return;
		// add confirmation dialog
		const isConfirmed = window.confirm(
			"Είστε σίγουροι ότι θέλετε να αποθηκεύσετε τις αλλαγές;",
		);
		if (!isConfirmed) return;

		try {
			await axiosPrivate.put(
				`/auth/subscriptions/${editingSubscription.id}`,
				editingSubscription,
				{
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
				},
			);
			const updatedSubscriptions = subscriptions.map((sub) =>
				sub.id === editingSubscription.id ? editingSubscription : sub,
			);
			setSubscriptions(updatedSubscriptions);
			setPopOpen(false);
			setEditingSubscription(null);
		} catch (error) {
			console.error("Error updating subscription:", error);
			if (axios.isAxiosError(error)) {
				// Axios error
				toast.error(`${error.response?.data}`);
			} else if (error instanceof Error) {
				// General JavaScript error
				toast.error(`${error.message}`);
			} else {
				// Unknown error
				toast.error("Προέκυψε σφάλμα κατά την ενημέρωση της συνδρομής.");
			}
			// Handle error (e.g., show error message to user)
		}
	};

	const handleInputChange = (
		field: keyof Subscription,
		value: string | number,
	) => {
		if (!editingSubscription) return;
		setEditingSubscription((prev) => {
			if (!prev) return null;
			return { ...prev, [field]: value } as Subscription;
		});
	};

	// useEffect checkIfDateIsBetween from_date and to_date and if true set is_active to true
	useEffect(() => {
		if (!editingSubscription) return;
		const isActive = checkIfDateIsBetween(
			editingSubscription.from_date,
			editingSubscription.to_date,
		);
		setEditingSubscription((prev) => {
			if (!prev) return null;
			return { ...prev, is_active: isActive ? 1 : 0 } as Subscription;
		});
	});

	return (
		<TableRow key={subscription.id}>
			<TableCell>{subscription.package_name}</TableCell>
			<TableCell>
				<Badge color={subscription.is_active ? "green" : "gray"}>
					{subscription.is_active ? "Ενεργή" : "Ανενεργή"}
				</Badge>
			</TableCell>
			<TableCell>{subscription.allowed_users}</TableCell>
			<TableCell>{subscription.allowed_credits}</TableCell>
			<TableCell>
				{subscription.allowed_credits - subscription.used_credits}
			</TableCell>

			<TableCell>
				{formatDate(subscription.from_date, { simple: true, withTime: false })}
			</TableCell>
			<TableCell>
				{formatDate(subscription.to_date, { simple: true, withTime: false })}
			</TableCell>
			<TableCell>
				{" "}
				<Popover open={popOpen}>
					<PopoverTrigger
						onClick={() => {
							setPopOpen(!popOpen);
						}}
					>
						<Button
							icon={PencilIcon}
							variant="secondary"
							onClick={() => handleEdit(subscription)}
						>
							Edit
						</Button>
					</PopoverTrigger>

					<PopoverContent className="w-80">
						<div className="grid gap-4">
							<div className="space-y-2">
								<h4 className="font-medium leading-none">
									Επεξεργασία Συνδρομής
								</h4>
								<form
									onSubmit={(e) => {
										e.preventDefault();
										handleSave();
									}}
								>
									<div className="space-y-2">
										<div className="flex flex-col items-start space-y-1">
											<label
												htmlFor="allowed_users"
												className="text-sm font-medium"
											>
												Επιτρεπόμενοι Χρήστες
											</label>
											<NumberInput
												id="allowed_users"
												placeholder="Allowed Users"
												value={editingSubscription?.allowed_users || 0}
												onValueChange={(value) =>
													handleInputChange("allowed_users", value)
												}
												min={0}
											/>
										</div>
										<div className="flex flex-col items-start space-y-1">
											<label
												htmlFor="allowed_credits"
												className="text-sm font-medium"
											>
												Επιτρεπόμενα Credits
											</label>
											<NumberInput
												id="allowed_credits"
												placeholder="Allowed Credits"
												value={editingSubscription?.allowed_credits || 0}
												onValueChange={(value) =>
													handleInputChange("allowed_credits", value)
												}
												min={0}
											/>
										</div>
										{/* <div className="flex flex-col items-start space-y-1">
											<label
												htmlFor="from_date"
												className="text-sm font-medium"
											>
												Ημερομηνία Έναρξης
											</label>
											<input
												type="date"
												id="from_date"
												value={editingSubscription?.from_date || ""}
												onChange={(e) =>
													handleInputChange("from_date", e.target.value)
												}
												className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
											/>
										</div> */}
										<div className="flex flex-col items-start space-y-1">
											<label htmlFor="to_date" className="text-sm font-medium">
												Ημερομηνία Λήξης
											</label>
											<input
												type="date"
												id="to_date"
												value={editingSubscription?.to_date || ""}
												onChange={(e) =>
													handleInputChange("to_date", e.target.value)
												}
												className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
											/>
										</div>
										<div className="flex justify-end space-x-2">
											<Button
												type="button"
												variant="secondary"
												onClick={() => setPopOpen(false)}
											>
												Ακύρωση
											</Button>
											<Button type="submit" color="blue">
												Αποθήκευση{" "}
											</Button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</PopoverContent>
				</Popover>
			</TableCell>
		</TableRow>
	);
};
